import { FullScreenError } from "@/components/full-screen-states/error";
import { FullScreenLoading } from "@/components/full-screen-states/loading";
import { GithubLogo } from "@/components/icons";
import { InstallCard } from "@/components/setup/setup-card";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { ToastAction } from "@/components/ui/toast";
import { graphql } from "@/graphql";
import { useToast } from "@/hooks/use-toast";
import { useQuery } from "@apollo/client";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Info } from "lucide-react";
import { useEffect } from "react";
import * as z from "zod";

const getGraphqlInstallation = graphql(`
  query getGraphqlInstallation {
    viewer {
      ...UserBase
      githubAppInstallation {
        id
        actorLogin
        actorType
        repositories {
          uuid
        }
      }
    }
  }
`);

const pageSearch = z.object({
  error: z.string().optional(),
});

export const Route = createFileRoute("/setup/github/")({
  component: GithubInstallationPage,
  validateSearch: (search) => pageSearch.parse(search),
});

const githubAppInstallLink = `https://github.com/apps/${import.meta.env.VITE_GITHUB_APP_SLUG}/installations/select_target`;

function GithubInstallationPage() {
  const navigate = useNavigate();
  const { error } = Route.useSearch();
  const { loading, error: queryError, data } = useQuery(getGraphqlInstallation);
  const { toast } = useToast();

  useEffect(() => {
    if (error === "too_many_repositories") {
      toast({
        title: "Multiple Repositories",
        description:
          "You have installed more than one repository. Please uninstall and install back the GitHub app and select just one repository.",
        variant: "destructive",
        duration: 35000,
        action: (
          <a href={githubAppInstallLink} target="_blank" rel="noreferrer">
            <ToastAction
              altText="Uninstall Github app"
              className="border-white!"
            >
              Uninstall
            </ToastAction>
          </a>
        ),
      });
    }

    if (
      data?.viewer &&
      data.viewer.githubAppInstallation !== null &&
      data.viewer.githubAppInstallation?.repositories?.length === 1
    ) {
      void navigate({
        to: "/setup/issue-trackers",
      });
      return;
    }
  }, [data, navigate, toast, error]);

  if (loading) {
    return <FullScreenLoading />;
  }

  if (!data?.viewer || queryError) {
    console.error("queryError", queryError);
    console.error("data", data);
    return <FullScreenError />;
  }

  return (
    <InstallCard
      title="Install GitHub App"
      description={
        <span>
          Connect your GitHub account so that Producta can access your
          repositories and open pull requests.
          <InstallOneRepository />
        </span>
      }
      Icon={GithubLogo}
      stepOrder="2"
      installLink={githubAppInstallLink}
    />
  );
}

const InstallOneRepository = () => (
  <Alert className="mt-5">
    <Info className="h-4 w-4" />
    <AlertTitle>Heads up!</AlertTitle>
    <AlertDescription>
      You should only install <strong>one</strong> repository.
    </AlertDescription>
  </Alert>
);
