import { FullScreenError } from "@/components/full-screen-states/error";
import { FullScreenLoading } from "@/components/full-screen-states/loading";
import { ErrorMessages } from "@/config/error-messages";
import { graphql } from "@/graphql";
import { CreateGithubAppInstallationStatus } from "@/graphql/graphql";
import { useMutation } from "@apollo/client";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";

export const Route = createFileRoute("/setup/github/callback")({
  component: RouteComponent,
});

const createGithubAppInstallation = graphql(`
  mutation createGithubAppInstallation($installationId: String!) {
    createGithubAppInstallation(installationId: $installationId) {
      status
      githubAppInstallation {
        id
      }
    }
  }
`);

function RouteComponent() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [createGithubApp] = useMutation(createGithubAppInstallation);

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const installationId = urlParams.get("installation_id");

        if (!installationId) {
          setErrorMessage(ErrorMessages.GENERAL);
          return;
        }

        const result = await createGithubApp({
          variables: {
            installationId,
          },
        });

        const tooManyRepositories =
          result?.data?.createGithubAppInstallation?.status ===
          CreateGithubAppInstallationStatus.TooManyRepositories;

        if (tooManyRepositories) {
          void navigate({
            to: "/setup/github",
            search: {
              error: "too_many_repositories",
            },
          });
          return;
        }

        void navigate({ to: "/setup/issue-trackers" });
        return;
      } catch (error) {
        console.error(error);
        setErrorMessage(ErrorMessages.GENERAL);
        return;
      }
    };

    void handleCallback();
  }, [createGithubApp, navigate]);

  if (errorMessage) {
    return (
      <FullScreenError
        title="Connection Failed"
        message="Failed to connect to GitHub"
        actions={[
          {
            label: "Try Again",
            onClick: () => void window.location.reload(),
            variant: "default",
          },
        ]}
      />
    );
  }

  return (
    <FullScreenLoading message="Connecting your GitHub account. This may take a minute..." />
  );
}
