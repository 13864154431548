import { cn } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { motion } from "framer-motion";
import type { ReactNode } from "react";
import { Button } from "../ui/button";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";

export const InstallCard = ({
  title,
  description,
  Icon,
  stepOrder,
  installLink,
  installButtonText,
  openInNewTab,
}: {
  title?: string;
  description: ReactNode;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  stepOrder: "1" | "2" | "3";
  installLink: string;
  installButtonText?: string;
  openInNewTab?: boolean;
}) => {
  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-center p-4 relative overflow-hidden">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <Card className="border-white/10 backdrop-blur-xl">
          <CardHeader className="flex flex-col">
            <h2 className="flex font-semibold text-white">
              {Icon && <Icon className="w-5 h-5 mr-2" />} {title}
            </h2>
            <span className="text-sm text-muted-foreground">{description}</span>
          </CardHeader>
          <CardContent className="pb-4">
            <Link to={installLink} target={openInNewTab ? "_blank" : undefined}>
              <Button size="default" className="w-full transition-all">
                {installButtonText ?? "Install"}
              </Button>
            </Link>
          </CardContent>
          <CardFooter className="flex flex-col">
            <div className="w-full flex items-center justify-between text-sm text-muted-foreground">
              <span>Step {stepOrder} of 3</span>
              <div className="flex gap-1">
                <div className="w-8 h-1 rounded-full bg-gray-400" />
                <div
                  className={cn(
                    "w-8 h-1 rounded-full",
                    stepOrder === "2" || stepOrder === "3"
                      ? "bg-gray-400"
                      : "bg-gray-400/20",
                  )}
                />
                <div
                  className={cn(
                    "w-8 h-1 rounded-full",
                    stepOrder === "3" ? "bg-gray-400" : "bg-gray-400/20",
                  )}
                />
              </div>
            </div>
          </CardFooter>
        </Card>
        <div className="text-center text-muted-foreground text-sm mt-5">
          Need help?{" "}
          <a
            href="mailto:hello@producta.ai"
            className="text-muted-foreground font-medium underline underline-offset-4"
          >
            Contact support
          </a>
        </div>
      </motion.div>
    </div>
  );
};
